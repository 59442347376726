<template>
  <div class="add-reserve-dialog">
    <page-dialog
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="添加占用"
      :isShowFooter="true"
      @handleSaveClick="handleSaveClick"
    >
      <div class="item">
        <div class="key">理疗师:</div>
        <div class="value">{{ addInfo.teacherName }}</div>
      </div>
      <div class="item">
        <div class="key">日期:</div>
        <div class="value">{{ dayjs(ruleForm.arriveDate).format("YYYY年MM月DD日") }}</div>
      </div>
      <div class="item">
        <div class="key">可选时间:</div>
        <div class="value"></div>
      </div>
      <div class="time-list">
        <el-scrollbar height="250px">
          <el-checkbox-group v-model="checkList">
            <template :key="item" v-for="item in reserveTimeList">
              <el-checkbox :label="item.time">
                {{ item.time }}
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </el-scrollbar>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { tips } from '@/utils/tips'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
import cache from '@/utils/cache'
import ruleFormVue from '@/views/main/reserve/site/base-ui/rule-form/rule-form.vue'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { isSchedule, setTeacherTimeOccupy } from '@/service/main/reserve'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    addInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible','refreshOccupyTime'],
  setup(props, { emit }) {
    const store = useStore()

    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }
    const reserveTimeList = computed(() => store.state.reserve.reserveTimeList)
    const ruleForm = reactive({
      appNo: "YYYYMMDDHIS",
      companyId: COMPANY_ID,
      userId: "",
      serveName: '',
      serveId: '',
      paraId: '',
      shopId: "",
      arriveDate: new Date(),
      arriveTime: "",
      appTimes: "",
      empId: "",
      prepay: 0,
      prepayAmount: 0,
      promPrice: 0,
      appStatus: 1,
      appMemo: "",
      isPoint: 0,
      servePrice: "",
      serveTimeLength: "",
      arriveKind: 1,
      arrivePerson: '',
      userMobile: "",
      toShopPreson: "",
    })

    // emitter.on('changeAddTimeOccopyDialogTime', time => {
    //   ruleForm.arriveDate = time
    // })

    watch(() => props.addInfo, (n) => {
      ruleForm.empId = n.teacherId
      ruleForm.arriveTime = n.startTime
    }, {
      deep: true
    })



    const shopTeacherList = computed(() => store.state.reserve.shopTeacherList)

    const checkList = ref([])
    const currentOptServe = reactive({
      serveName: '',
      serveId: "",
      flag: false,
      paraId: ""
    })
    const handleServeClick = (item) => {
      currentOptServe.serveName = item.serveName
      currentOptServe.serveId = item.serveId
      ruleForm.serveName = item.serveName
      ruleForm.serveId = item.serveId

    }
    const handleCheckBoxChange = (value) => {
      currentOptServe.flag = value
      // console.log(value);
    }




    const handleSaveClick = async () => {
      if (checkList.value.length == 0) {
        ElMessage({
          message: '请先选择时间!',
          type: 'warning',
        })
        return
      }

      // 判断这个理疗师是否排班
      const flagRes = await isSchedule({
        empId: props.addInfo.teacherId,
        appDate: dayjs(ruleForm.arriveDate).format('YYYY-MM-DD'),
        appTime: checkList.value.join(),
        cause:1
      })

      if (flagRes.code != 0) {
        ElMessage({
          message: '该理疗师未排班，请先去排班!',
          type: 'warning',
        })
        return
      }

      let obj = {
        userId: props.addInfo.teacherId,
        status: 3,
        appDate: dayjs(ruleForm.arriveDate).format('YYYY-MM-DD'),
        appTime: checkList.value.join()
      }
      const res = await setTeacherTimeOccupy(obj)
      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        emit('refreshOccupyTime')
      }
      // store.dispatch('reserve/setTeacherTimeOccopyAction', obj)

    }




    const initPage = () => {
      store.dispatch('reserve/getShopListAction', COMPANY_ID)
      let adminInfo = cache.getCache('adminInfo')
      ruleForm.shopId = adminInfo.shopId
      store.dispatch('reserve/getShopTeacherListAction', adminInfo.shopId)
    }

    initPage()




    return {
      reserveTimeList,
      dayjs,
      handleSaveClick,
      handleCheckBoxChange,
      handleServeClick,
      currentOptServe,
      checkList,
      ruleForm,
      changeDialogVisible,
      COMPANY_ID,
      shopTeacherList
    }

  }
})
</script>

<style scoped lang="less">
.add-reserve-dialog {
  .time-list {
    &:deep(.el-checkbox) {
      display: block;
    }

    padding: 10px;
    background-color: rgb(239, 239, 239);
  }

  .item {
    display: flex;
    font-size: 16px;
    margin: 20px 0;

    .key {
      font-weight: 550;
      min-width: 60px;
    }
  }
}
</style>
