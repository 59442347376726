const roomBreadcrumbList = [
  {
    name: '预约管理',
    path: ''
  },
  {
    name: '预约列表',
    path: ''
  },
  {
    name: '房间维度',
    path: ''
  }
]

const physiotherapyBreadcrumbList = [
  {
    name: '预约管理',
    path: ''
  },
  {
    name: '预约列表',
    path: ''
  },
  {
    name: '理疗维度',
    path: ''
  }
]

export { roomBreadcrumbList, physiotherapyBreadcrumbList }
