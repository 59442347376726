export const reserveTimeList = [
  {
    time: '07:00'
  },
  {
    time: '07:15'
  },
  {
    time: '07:30'
  },
  {
    time: '07:45'
  },
  {
    time: '08:00'
  },
  {
    time: '08:15'
  },
  {
    time: '08:30'
  },
  {
    time: '08:45'
  },
  {
    time: '09:00'
  },
  {
    time: '09:15'
  },
  {
    time: '09:30'
  },
  {
    time: '09:45'
  },
  {
    time: '10:00'
  },
  {
    time: '10:15'
  },
  {
    time: '10:30'
  },
  {
    time: '10:45'
  },
  {
    time: '11:00'
  },
  {
    time: '11:15'
  },
  {
    time: '11:30'
  },
  {
    time: '11:45'
  },
  {
    time: '12:00'
  },
  {
    time: '12:15'
  },
  {
    time: '12:30'
  },
  {
    time: '12:45'
  },
  {
    time: '13:00'
  },
  {
    time: '13:15'
  },
  {
    time: '13:30'
  },
  {
    time: '13:45'
  },
  {
    time: '14:00'
  },
  {
    time: '14:15'
  },
  {
    time: '14:30'
  },
  {
    time: '14:45'
  },
  {
    time: '15:00'
  },
  {
    time: '15:15'
  },
  {
    time: '15:30'
  },
  {
    time: '15:45'
  },
  {
    time: '16:00'
  },
  {
    time: '16:15'
  },
  {
    time: '16:30'
  },
  {
    time: '16:45'
  },
  {
    time: '17:00'
  },
  {
    time: '17:15'
  },
  {
    time: '17:30'
  },
  {
    time: '17:45'
  },
  {
    time: '18:00'
  },
  {
    time: '18:15'
  },
  {
    time: '18:30'
  },
  {
    time: '18:45'
  },
  {
    time: '19:00'
  },
  {
    time: '19:15'
  },
  {
    time: '19:30'
  },
  {
    time: '19:45'
  },
  {
    time: '20:00'
  },

  {
    time: '20:15'
  },

  {
    time: '20:30'
  },

  {
    time: '20:45'
  },
  {
    time: '21:00'
  },
  {
    time: '21:15'
  },
  {
    time: '21:30'
  },
  {
    time: '21:45'
  },
  {
    time: '22:00'
  },
  {
    time: '22:15'
  },
  {
    time: '22:30'
  },
  {
    time: '22:45'
  },
  {
    time: '23:00'
  },
  {
    time: '23:15'
  },
  {
    time: '23:30'
  },
  {
    time: '23:45'
  },
  {
    time: '24:00'
  }
]


export const reserveTimeList_30 = [
  {
    time: '07:00'
  },
  {
    time: '07:30'
  },
  {
    time: '08:00'
  },
  {
    time: '08:30'
  },
  {
    time: '09:00'
  },
  {
    time: '09:30'
  },
  {
    time: '10:00'
  },
  {
    time: '10:30'
  },
  {
    time: '11:00'
  },
  {
    time: '11:30'
  },
  {
    time: '12:00'
  },
  {
    time: '12:30'
  },
  {
    time: '13:00'
  },

  {
    time: '13:30'
  },

  {
    time: '14:00'
  },
  {
    time: '14:30'
  },
  {
    time: '15:00'
  },
  {
    time: '15:30'
  },
  {
    time: '16:00'
  },
  {
    time: '16:30'
  },
  {
    time: '17:00'
  },
  {
    time: '17:30'
  },
  {
    time: '18:00'
  },
  {
    time: '18:30'
  },
  {
    time: '19:00'
  },
  {
    time: '19:30'
  },
  {
    time: '20:00'
  },
  {
    time: '20:30'
  },
  {
    time: '21:00'
  },
  {
    time: '21:30'
  },
  {
    time: '22:00'
  },
  {
    time: '22:30'
  },
  {
    time: '23:00'
  },
  {
    time: '23:30'
  },
  {
    time: '24:00'
  }
]



export const reserveTimeList_60 = [
  {
    time: '07:00'
  },
  {
    time: '08:00'
  },
  {
    time: '09:00'
  },
  {
    time: '10:00'
  },
  {
    time: '11:00'
  },
  {
    time: '12:00'
  },
  {
    time: '13:00'
  },
  {
    time: '14:00'
  },
  {
    time: '15:00'
  },
  {
    time: '16:00'
  },
  {
    time: '17:00'
  },
  {
    time: '18:00'
  },
  {
    time: '19:00'
  },
  {
    time: '20:00'
  },
  {
    time: '21:00'
  },
  {
    time: '22:00'
  },
  {
    time: '23:00'
  },
  {
    time: '24:00'
  }
]



export const roomTimeList = [
  {
    time: '07:00'
  },
  {
    time: '07:15'
  },
  {
    time: '07:30'
  },
  {
    time: '07:45'
  },
  {
    time: '08:00'
  },
  {
    time: '08:15'
  },
  {
    time: '08:30'
  },
  {
    time: '08:45'
  },
  {
    time: '09:00'
  },
  {
    time: '09:15'
  },
  {
    time: '09:30'
  },
  {
    time: '09:45'
  },
  {
    time: '10:00'
  },
  {
    time: '10:15'
  },
  {
    time: '10:30'
  },
  {
    time: '10:45'
  },
  {
    time: '11:00'
  },
  {
    time: '11:15'
  },
  {
    time: '11:30'
  },
  {
    time: '11:45'
  },
  {
    time: '12:00'
  },
  {
    time: '12:15'
  },
  {
    time: '12:30'
  },
  {
    time: '12:45'
  },
  {
    time: '13:00'
  },
  {
    time: '13:15'
  },
  {
    time: '13:30'
  },
  {
    time: '13:45'
  },
  {
    time: '14:00'
  },
  {
    time: '14:15'
  },
  {
    time: '14:30'
  },
  {
    time: '14:45'
  },
  {
    time: '15:00'
  },
  {
    time: '15:15'
  },
  {
    time: '15:30'
  },
  {
    time: '15:45'
  },
  {
    time: '16:00'
  },
  {
    time: '16:15'
  },
  {
    time: '16:30'
  },
  {
    time: '16:45'
  },
  {
    time: '17:00'
  },
  {
    time: '17:15'
  },
  {
    time: '17:30'
  },
  {
    time: '17:45'
  },
  {
    time: '18:00'
  },
  {
    time: '18:15'
  },
  {
    time: '18:30'
  },
  {
    time: '11:45'
  },
  {
    time: '19:00'
  },
  {
    time: '19:15'
  },
  {
    time: '19:30'
  },
  {
    time: '19:45'
  },
  {
    time: '20:00'
  },
  {
    time: '20:15'
  },

  {
    time: '20:30'
  },

  {
    time: '20:45'
  },
  {
    time: '21:00'
  },
  {
    time: '21:15'
  },
  {
    time: '21:30'
  },
  {
    time: '21:45'
  },
  {
    time: '22:00'
  },
  {
    time: '22:15'
  },
  {
    time: '22:30'
  },
  {
    time: '22:45'
  },
  {
    time: '23:00'
  },
  {
    time: '23:15'
  },
  {
    time: '23:30'
  },
  {
    time: '23:45'
  },
  {
    time: '24:00'
  }
]

export const timeGapOption = [
  {
    label: '15分钟',
    value: '15'
  },
  {
    label: '30分钟',
    value: '30'
  },
  {
    label: '60分钟',
    value: '60'
  }
]
