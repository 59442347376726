<template>
  <div class="check">
    <breadcrumb :breadcrumbList="physiotherapyBreadcrumbList"></breadcrumb>
    <div class="check-content">
      <top-content @currentTabsChange="currentTabsChange"></top-content>
     
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { roomBreadcrumbList, physiotherapyBreadcrumbList } from './config'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import TopContent from './cpns/top-content/top-content.vue'
export default defineComponent({
  props: {

  },
  components: {
    breadcrumb,
    TopContent
  },
  setup() {
    const currentTabs = ref('room-list')
    const currentTabsChange = (value) => {
      currentTabs.value = value
    }

    return {
      roomBreadcrumbList,
      physiotherapyBreadcrumbList,
      currentTabsChange,
      currentTabs
    }

  }
})
</script>

<style scoped lang="less">
.check {
  overflow: scroll;

  .check-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
    min-width: 960px;
  }
}
</style>




