<template>
  <div ref="contentRef" class="content">
    <div class="top-content">
      <div class="left">
        <el-button class="add-btn" type="primary" @click="handleBackClick">
          返回
        </el-button>
        <div class="staff-info">
          {{ $route.query.userRealname + '(' + $route.query.shopName + ')' }}
        </div>
        <el-date-picker @change="handleDateChange" v-model="ruleForm.date" type="daterange" range-separator="到"
          start-placeholder="开始日期" end-placeholder="结束日期" />
      </div>
    </div>

    <div :style="{ height: mainHeight.height1 + 'px' }" class="main-content">
      <div style="overflow:scroll; height: 100%;">
        <physiotherapy-list :contentWidth="contentWidth"></physiotherapy-list>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch,onMounted } from 'vue'
import PhysiotherapyList from '../physiotherapy-list/physiotherapy-list.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'
import dayjs from 'dayjs'
import { timeGapOption } from '../config'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {

  },
  components: {
    PhysiotherapyList,
  },
  emits: ['currentTabsChange'],
  setup(props, { emit }) {
    const router = useRouter()
    const store = useStore()
    const currentTabs = ref('physiotherapy-list')

    const handleIconClick = (type) => {
      currentTabs.value = type
      emit('currentTabsChange', type)
    }


    const ruleForm = reactive({
      shopId: '',
      date: new Date(),
      timeGap: '10',
      date: ''
    })

    const handleShopChange = (value) => {

    }

    const scrollInfo = reactive({
      x: 0,
      y: 0
    })
    const scroll = (x, y) => {
      scrollInfo.x = x
    }

    const handleDateChange = value => {
      // console.log(value);
      emitter.emit('teacherDetailCheckOptDateChange', value)
    }

    const shopList = computed(() => store.state.reserve.shopList)
    const handleTimeChange = (value) => {
      let time = dayjs(value).format('YYYY-MM-DD')
    }

    const mainHeight = reactive({
      height1: 6350
    })

    const handleTimeGapChange = value => {
      cache.setCache('reserveTimeDiff', value)
      emitter.emit('changeTeacherDetailAddReserveTime', value)
      let height
      if (value == 10) {
        height = 6350
      } else if (value == 30) {
        height = 2742
      } else {
        height = 1564
      }


      mainHeight.height1 = height

    }

    const handleBackClick = () => {
      router.go(-1)
    }

    const contentWidth=ref(1200)
    onMounted (()=>{
      contentWidth.value=contentRef.value.offsetWidth
    })

    const contentRef=ref()

    const initPage = () => {
      let reserveTimeDiff = cache.getCache('reserveTimeDiff')
      if (reserveTimeDiff != undefined) {
        ruleForm.timeGap = reserveTimeDiff
        handleTimeGapChange(reserveTimeDiff)
      }


      store.dispatch('reserve/getShopListAction', COMPANY_ID)
      let adminInfo = cache.getCache('adminInfo')
      ruleForm.shopId = adminInfo.shopId
    }
    initPage()


    return {
      contentWidth,
      contentRef,
      scroll,
      handleBackClick,
      mainHeight,
      handleTimeGapChange,
      handleShopChange,
      ruleForm,
      shopList,
      currentTabs,
      handleIconClick,
      COMPANY_ID,
      handleTimeChange,
      timeGapOption,
      handleDateChange,
      scrollInfo

    }

  }
})
</script>

<style scoped lang="less">
.content {
  &:deep(.el-scrollbar__view) {
    position: relative;
  }

  &:deep(.time-gap-select) {
    margin-left: 10px;
  }

  .main-content {
    margin-top: 20px;
    position: relative;
    overflow:hidden;
  }

  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 100%;
      display: flex;

      .staff-info {
        display: flex;
        align-items: center;
        font-size: 13px;
        margin: 0 10px;

      }

      .add-btn {
        margin-right: 10px;
      }

      &:deep(.el-select) {
        margin-right: 10px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .active-icon {
        border: 1px solid rgba(255, 87, 51, 1) !important;

        i {
          color: rgba(255, 87, 51, 1);
        }
      }

      .icon {
        cursor: pointer;
        padding: 6px;
        margin: 0 6px;
        border: 1px solid rgba(166, 166, 166, 1);

        i {
          font-size: 20px;
        }
      }
    }
  }
}
</style>




