<template>
  <div class="add-reserve-dialog">
    <page-dialog @handleDialogClick="handleDialogClick" width="500px" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible" title="添加预约" :isShowFooter="true" @handleSaveClick="handleSaveClick">
      <el-scrollbar @scroll="scrollbarScroll" @click="handlePageDialogClick" height="400px">
        <el-form label-position="left" :model="ruleForm" label-width="90px" class="demo-ruleForm">
          <el-form-item label="姓名/手机号：" prop="name">
            <div class="search-user">
              <div class="phone">
                <el-input @keyup="handlePhoneEnter" @change="handlePhonechange" v-model.trim="ruleForm.userMobile"
                  placeholder="" />
                <div v-if="currentUser.hasOwnProperty('userAva') && isShowUserInfo" class="user-info">
                  <img :src="currentUser.userAva" alt="">
                  <div class="name">{{  currentUser.userRealname  }}</div>
                  <div class="phone">{{  currentUser.userMobile  }}</div>
                  <el-icon @click="handleClosrClick" class="close">
                    <close />
                  </el-icon>
                </div>
              </div>
              <div v-if="isShowSearchTips" class="search-tips">
                <template :key="useritem" v-for="useritem in searchResList">
                  <div @click="handleSearchUserClick(useritem)" class="user-item">
                    <img :src="useritem.userAva" alt="">
                    <div class="name">{{  useritem.userRealname  }}</div>
                  </div>
                </template>
              </div>
            </div>
          </el-form-item>

          <!-- <el-form-item label="到店人：" prop="name">
            <div class="to-shop">
              <el-radio-group v-model="ruleForm.toShopPreson">
                <el-radio :label="1">本人到店</el-radio>
                <el-radio :label="2">他人到店</el-radio>
              </el-radio-group>
              <div class="nickname">
                <el-input v-model="ruleForm.arrivePerson" placeholder="到店人姓名" />
              </div>
            </div>
          </el-form-item> -->

          <el-form-item label="选择门店:">
            <div class="shop">
              <el-select @change="handleShopChange" v-model="ruleForm.shopId" class="m-2" placeholder="选择门店"
                size="large">
                <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
                </el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="选择理疗师:">
            <div class="teacher">
              <el-select @change="handleTeacherChange" class="teacher" v-model="ruleForm.empId" placeholder="选择理疗师"
                size="large">
                <el-option v-for="item in shopTeacherList" :key="item.userId" :label="item.userRealname"
                  :value="item.userId" />
              </el-select>
              <el-select @change="handleServeTypeChange" class="teacher" v-model="optServeId" placeholder="选择服务分类"
                size="large">
                <el-option v-for="item in teacherServeCategoryList" :key="item.kindId" :label="item.kindName"
                  :value="item.kindId" />
              </el-select>
            </div>

            <div class="serve-content">
              <div class="title">
                <div>项目</div>
                <div>时长</div>
                <div>规格</div>
              </div>

              <div v-if="currentStaffIndex != 0" class="serve-list">
                <h1>123</h1>
                <el-scrollbar height="200px">
                  <template :key="item" v-for="item in teacherServeInfoList">
                    <div @click="handleServeClick(item)" class="item">
                      <div class="left">
                        <div class="name">{{  item.serveName  }}</div>
                      </div>
                      <div class="right">
                        <el-radio-group v-if="item.paralist.length > 0" v-model="checkList">
                          <template :key="ele" v-for="ele in item.paralist">
                            <div @click="handleCheckbox(ele)">
                              <el-radio @change="handleCheckBoxChange" :label="ele.paraId">
                                <div class="checkbox">
                                  <div>{{  ele.serveTimeLength  }}分钟</div>
                                  <div class="salePrice">¥{{  (ele.salePrice) / 100  }}</div>
                                </div>
                              </el-radio>
                            </div>
                          </template>
                        </el-radio-group>
                      </div>
                    </div>
                  </template>
                </el-scrollbar>
              </div>
              <div v-else class="serve-list">
                <template :key="item" v-for="item in shopServeList">
                  <div @click="handleServeClick(item)" class="item">
                    <div class="left">
                      <div class="name">{{  item.serveName  }}</div>
                    </div>
                    <div class="right">
                      <el-radio-group v-if="item.paralist.length > 0" v-model="checkList">
                        <template :key="ele" v-for="ele in item.paralist">
                          <div @click="handleCheckbox(ele)">
                            <el-radio @change="handleCheckBoxChange" :label="ele.paraId">
                              <div class="checkbox">
                                <div>{{  ele.serveTimeLength  }}分钟</div>
                                <div class="salePrice">¥{{  (ele.salePrice) / 100  }}</div>
                              </div>
                            </el-radio>
                          </div>
                        </template>
                      </el-radio-group>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="到店日期:">
            <div class="arrive-date">
              <el-date-picker :disabled-date="disabledDate" class="arriveDate" v-model="ruleForm.arriveDate" type="date"
                placeholder="请选择日期" />
              <el-time-select v-model="ruleForm.arriveTime" :start="timeStart" step="00:15" end="22:00"
                placeholder="选择时间" />
            </div>
          </el-form-item>
          <el-form-item label="备注:">
            <div class="remark">
              <el-input v-model="ruleForm.appMemo" :rows="2" type="textarea" placeholder="请输入备注" />
            </div>
          </el-form-item>
        </el-form>
      </el-scrollbar>

    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { tips } from '@/utils/tips'
import { COMPANY_ID, RESERVE_START_TIME } from '@/common/constant'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
import cache from '@/utils/cache'
import { nextTick } from 'process'
import ruleFormVue from '@/views/main/reserve/site/base-ui/rule-form/rule-form.vue'
import { searchKeywordCustomer, getShopServeList, getServeCategoryList,newApp} from '@/service/main/reserve'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'

import {
  debounce
} from 'lodash'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    addInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    currentStaffIndex: {
      type: String,
      default: '0'
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()

    const changeDialogVisible = (flag) => {
      if (flag) {
        let optarrive = props.addInfo.startTime
        let date1 = dayjs(ruleForm.arriveDate).format('YYYY-MM-DD')
        let date2 = dayjs(new Date()).format('YYYY-MM-DD')
        let optdate = dayjs(date1).valueOf()
        let curdate = dayjs(date2).valueOf()
        // 如果是当天

        if (optdate == curdate) {
          let date3 = dayjs(date1 + optarrive).valueOf()
          let date4 = dayjs(new Date()).valueOf()
          if (date3 > date4) {
            ruleForm.arriveTime = optarrive
          }
        } else {
          ruleForm.arriveTime = optarrive
        }

      }


      emit('changeDialogVisible', flag)
      handleClosrClick()


    }

    const ruleForm = reactive({
      appNo: "",
      companyId: COMPANY_ID,
      userId: "",
      serveName: '',
      serveId: '',
      paraId: '',
      shopId: "",
      arriveDate: new Date(),
      arriveTime: "",
      appTimes: "",
      empId: "",
      prepay: 0,
      prepayAmount: 0,
      promPrice: 0,
      appStatus: 1,
      appMemo: "",
      isPoint: 0,
      servePrice: "",
      serveTimeLength: "",
      arriveKind: 1,
      arrivePerson: '',
      userMobile: "",
      toShopPreson: 1,
    })

    const optServeId = ref('')

    const handleClosrClick = () => {
      ruleForm.userId = ''
      ruleForm.toShopPreson = ''
      ruleForm.arrivePerson = ''
      isShowUserInfo.value = false
      ruleForm.userMobile = ''
    }

    const emptyForm = () => {
      for (let key in ruleForm) {
        if (key == 'shopId' || key == 'companyId' || key == 'prepay' || key == 'arriveDate' || key == 'prepayAmount' || key == 'promPrice' || key == 'appStatus' || key == 'isPoint' || key == 'arriveKind') {
          continue;
        } else {
          ruleForm[key] = ''
        }
      }
    }

    emitter.on('changeAddReserveDialogTime', time => {
      ruleForm.arriveDate = time
    })

    emitter.on('changeAddReserveDialogShopId', shopId => {
      ruleForm.shopId = shopId
      store.dispatch('reserve/getShopTeacherListAction', shopId)
    })

    const timeStart = computed(() => {
      let mm = dayjs(ruleForm.arriveDate).format('mm')
      let HH = dayjs(ruleForm.arriveDate).format('HH')
      if (mm > 0 && mm < 15) {
        mm = '15'
      }
      else if (mm > 15 && mm < 30) {
        mm = '30'
      }

      else if (mm > 30 && mm < 45) {
        mm = '45'
      }

      else if (mm > 45 && mm <= 59) {
        mm = '00'
        HH = Number(HH) + 1
      }
      let time = String(HH) + ':' + String(mm)
      return time

    })


    const shopList = computed(() => store.state.reserve.shopList)
    const shopTeacherList = computed(() => store.state.reserve.shopTeacherList)
    const teacherServeList = computed(() => store.state.reserve.teacherServeList)
    const clientInfo = computed(() => store.state.reserve.clientInfo)
    const teacherServeInfoList = ref([])
    watch(teacherServeList, (n) => {
      if (n != null) {
        teacherServeInfoList.value = n
      }
    }, {
      deep: true
    })

    const handleServeTypeChange = (value) => {
      let serveList = teacherServeList.value
      // console.log(serveList);
      // console.log(value);
      if (value.length == 0) {
        teacherServeInfoList.value = serveList
      } else {
        let arr = serveList.filter(ele => ele.serveKind == value)
        // console.log(arr);
        teacherServeInfoList.value = arr
      }
    }

    const getTeacherServeList = (tid) => {
      store.dispatch('reserve/getTeacherServeListAction', tid)
    }


    const shopServeList = ref()
    const getShopServeListFunc = async () => {
      let res = await getShopServeList(ruleForm.shopId)
      let list = res.data.list
      let serveList = []
      list.forEach(ele => {
        serveList.push({
          serveId: ele.serveId,
          serveName: ele.serveName,
          paralist: [
            {
              salePrice: ele.salePrice,
              serveTimeLength: ele.serveTimeLength,
              paraId: ele.paraId
            }
          ]
        })
      })
      shopServeList.value = serveList
    }
    watch(() => props.addInfo, async (n) => {
      if (n != null) {
        store.commit('reserve/changeClientInfo', {})
        checkList.value = null
        emptyForm()
        ruleForm.empId = n.teacherId
        if (props.currentStaffIndex != 0) {
          getTeacherServeList(n.teacherId)
        } else {
          getShopServeListFunc()
        }

        if (props.addInfo.teacherId) {
          const serveTypeRes = await getServeCategoryList(COMPANY_ID)
          let list = [...teacherServeCategoryList.value, ...serveTypeRes.data.list]
          teacherServeCategoryList.value = list
        }
      }
    }, {
      deep: true
    })

    const handleShopChange = (value) => {
      ruleForm.empId = ''
      store.dispatch('reserve/getShopTeacherListAction', value)
      getShopServeListFunc()
    }

    const handleTeacherChange = (value) => {
      // console.log('handleTeacherChange',value)
      getTeacherServeList(value)
    }

    const checkList = ref()
    const currentOptServe = reactive({
      serveName: '',
      serveId: "",
      flag: false,
      paraId: ""
    })
    const handleServeClick = (item) => {
      currentOptServe.serveName = item.serveName
      currentOptServe.serveId = item.serveId
      ruleForm.serveName = item.serveName
      ruleForm.serveId = item.serveId

    }
    const handleCheckBoxChange = (value) => {
      currentOptServe.flag = value
      // console.log(value);
    }
    const handleCheckbox = ele => {
      nextTick(() => {
        if (currentOptServe.flag) {
          currentOptServe.paraId = ele.paraId
          ruleForm.paraId = ele.paraId
          ruleForm.servePrice = ele.salePrice
          ruleForm.serveTimeLength = ele.serveTimeLength

        }
      })

    }
    const handlePhonechange = value => {
      ruleForm.userId = ''
      if (value.length >= 11) {
        store.dispatch('reserve/getClientInfoAction', value)
      }
    }


    const searchResList = ref([])
    const isShowSearchTips = ref(false)
    const handlePhoneEnter = async () => {
      const res = await searchKeywordCustomer({
        keyword: ruleForm.userMobile
      })
      searchResList.value = res.data.list
      if (ruleForm.userMobile.length == 0) {
        searchResList.value = []
      }
      if (searchResList.value.length > 0) {
        isShowSearchTips.value = true
      }



    }

    const handleDialogClick = () => {
      // console.log(1);
    }
    const handlePageDialogClick = () => {
      // console.log(1);
      isShowSearchTips.value = false
    }


    const isShowUserInfo = ref(false)
    watch(clientInfo, (n) => {
      if (n.hasOwnProperty('userId')) {
        isShowUserInfo.value = true
        ruleForm.userId = n.userId
        ruleForm.toShopPreson = 1
        ruleForm.arrivePerson = n.userRealname
        currentUser.userRealname = n.userRealname
        currentUser.userMobile = n.userMobile
        currentUser.userAva = n.userAva
      }

    })

    const currentUser = reactive({
      userRealname: '',
      userMobile: '',
      userAva: ''
    })

    const handleSearchUserClick = (item) => {
      isShowUserInfo.value = true
      ruleForm.userId = item.userId
      ruleForm.toShopPreson = 1
      ruleForm.arrivePerson = item.userRealname
      currentUser.userRealname = item.userRealname
      currentUser.userMobile = item.userMobile
      currentUser.userAva = item.userAva

    }



    const handleSaveClick = () => {
      // console.log(ruleForm)
          let adminInfo = cache.getCache('adminInfo')
          ruleForm.cashUid = adminInfo.userId
          let servers = [{
            serverId:ruleForm.serveId,
            paraId:ruleForm.paraId
          }]
          let params =  {
            "date": dayjs(ruleForm.arriveDate).format('YYYY-MM-DD'),
            "time": ruleForm.arriveTime,
            "empId": ruleForm.empId,
            "servers": servers,
            "userId": ruleForm.userId,
            "cashUid": ruleForm.cashUid
          }
          newApp(params).then(res=>{
              const {code,msg} = res
              if(code== 0){
                emit('changeDialogVisible', false)
              }else{
                ElMessage.error(msg)
              }
            })
      // store.dispatch('reserve/addReserveAction', { ...ruleForm })
    }

    const teacherServeCategoryList = ref([{
      kindId: '',
      kindName: '所有分类'
    }])

    const initPage = async () => {
      store.dispatch('reserve/getShopListAction', COMPANY_ID)
      let adminInfo = cache.getCache('adminInfo')
      ruleForm.shopId = adminInfo.shopId
      store.dispatch('reserve/getShopTeacherListAction', adminInfo.shopId)
      if (props.addInfo.teacherId) {
        const serveTypeRes = await getServeCategoryList(COMPANY_ID)
        let list = [...teacherServeCategoryList.value, ...serveTypeRes.data.list]
        teacherServeCategoryList.value = list
      }
    }

    initPage()

    const disabledDate = (time) => {
      return time.getTime() < Date.now() - 8.64e7
    }

    const scrollbarScroll = (e) => {
      // e.stopPropagation();
      // e.preventDefault();
    }




    return {
      handleServeTypeChange,
      teacherServeInfoList,
      optServeId,
      teacherServeCategoryList,
      RESERVE_START_TIME,
      getShopServeListFunc,
      shopServeList,
      scrollbarScroll,
      timeStart,
      disabledDate,
      currentUser,
      handleSearchUserClick,
      handleDialogClick,
      handlePageDialogClick,
      isShowSearchTips,
      searchResList,
      searchKeywordCustomer,
      handlePhoneEnter,
      isShowUserInfo,
      handleClosrClick,
      handleSaveClick,
      clientInfo,
      handlePhonechange,
      handleCheckbox,
      handleCheckBoxChange,
      handleServeClick,
      currentOptServe,
      checkList,
      teacherServeList,
      handleTeacherChange,
      handleShopChange,
      shopList,
      ruleForm,
      changeDialogVisible,
      COMPANY_ID,
      shopTeacherList,
      emptyForm
    }

  }
})
</script>

<style scoped lang="less">
.add-reserve-dialog {
  font-size: 12px;

  &:deep(.el-scrollbar__view) {
    padding-right: 20px;
  }

  &:deep(.is-horizontal) {
    display: none !important;
  }

  &:deep(.el-form-item__label) {
    font-size: 12px;
  }

  &:deep(.el-dialog) {
    font-size: 12px;
  }

  .search-user {
    position: relative;

    .search-tips {
      position: absolute;
      width: 231px;
      z-index: 100;
      background-color: #fff;
      // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
      margin-top: 6px;

      .user-item {
        display: flex;
        align-items: center;
        line-height: 46px;
        cursor: pointer;
        padding: 0 10px;
        border-bottom: 1px solid rgba(204, 204, 204, 1);


        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }


    }

    .phone {
      display: flex;

      &:deep(.el-input) {
        max-width: 136px !important;

        .el-input__inner {
          max-width: 136px;
        }
      }

      .user-info {
        width: 100%;
        display: flex;
        align-items: center;
        background-color: rgb(250, 232, 228);
        padding-left: 10px;
        position: relative;

        .close {
          position: absolute;
          right: 4px;
          top: 4px;
          font-size: 12px;
          cursor: pointer;

        }

        img {
          width: 30px;
          height: 30px;
          margin-right: 6px;
        }

        .name {
          margin-right: 6px;
        }
      }

      &:deep(.el-input) {
        max-width: 200px;
      }
    }
  }


  .to-shop {
    display: flex;

    .nickname {
      margin-left: 10px;
      flex: 1;
    }

    &:deep(.el-radio-group) {
      display: flex;
    }
  }

  .shop,
  .teacher {
    display: flex;
    justify-content: space-between;

    &:deep(.el-select) {
      width: 48%;
    }
  }

  .arrive-date {
    display: flex;
    justify-content: space-between;

    .arriveDate {
      margin-right: 10px;
    }

  }

  .serve-content {
    .title {
      display: flex;
      margin-top: 10px;
      padding: 0 10px;
      background-color: rgb(239, 239, 239);

      div {
        flex: 1;
        line-height: 40px;

      }
    }

    &:deep(.el-radio-group) {
      width: 100%;

      .el-radio {
        width: 100%;

        .el-radio__label {
          width: 100%;
        }
      }
    }

    .serve-list {
      padding: 0 10px;

      .item {
        display: flex;

        &:deep(.el-checkbox) {
          display: inline-block;
          width: 100% !important;

          .el-checkbox__label {
            display: inline-block;
            width: 100%;
          }
        }

        .left {
          flex: 1;
        }

        .right {
          flex: 2;

          .checkbox {
            display: flex;

            div {
              flex: 1;
            }

            .salePrice {
              color: rgb(255, 105, 74);
            }
          }
        }
      }
    }

    &:deep(.el-checkbox-group) {
      .el-checkbox-button {
        width: 100%;
        margin-bottom: 10px;

        span {
          width: 100%;
        }
      }
    }
  }

  // &:deep(.el-select) {
  //   max-width: 160px;
  // }

  &:deep(.el-input) {
    margin-right: 10px;
  }
}
</style>
