<template>
  <!-- 悬浮的 提示 -->
  <bottom-tips :detail="kanbanDetail"></bottom-tips>
  <div ref="sraffNameRef" class="title">
    <div ref="titleWrap1" class="title-wrap">
      <template :key="item" v-for="item in timeArr">
        <div class="date-item">
          <div>
            {{ item.date }}
          </div>
          <div class="date-week">
            {{ item.week }}
          </div>
        </div>
      </template>
    </div>
  </div>

  <!-- <div ref="bottomsraffNameRef" class="bottom-date-bar">
    <div ref="titleWrap2" class="title-wrap">
      <template :key="item" v-for="item in staffList">
        <div class="date-item">
          11111
          <div class="content-item"></div>
        </div>
      </template>
    </div>
  </div> -->
  <div class="physiotherapy-list">
    <div class="time">
      <div ref="timeScaleRef" class="time-scale">
        <template v-if="ruleForm.timeDiff == 10">
          <template :key="item" v-for="item in reserveTimeList">
            <div>
              {{ item.time }}
            </div>
          </template>
        </template>

        <template v-if="ruleForm.timeDiff == 30">

          <template :key="item" v-for="item in reserveTimeList">
            <div> <!--v-if="item.thirty"-->
              {{ item.time }}
            </div>
          </template>
        </template>

        <template v-if="ruleForm.timeDiff == 60">

          <template :key="item" v-for="item in reserveTimeList">
            <div> <!--v-if="item.sixty"-->
              {{ item.time }}
            </div>
          </template>
        </template>
      </div>
      <div ref="mainContentRef" class="line-list" v-if="reserveTimeList.length && timeArr.length">
        <template :key="indey" v-for="(t, indey) in reserveTimeList">
          <div class="line-box" :class="{ 'last': indey === reserveTimeList.length - 1 }">
            <!--v-if="checkIsShowLine(indey)"-->
            <template :key="index" v-for="(item, index) in timeArr">
              <!-- 已超时 -->
              <template v-if="getLineItem(indey, index).expire">
                <div class="line-item cursor">
                  <div v-if="getLineItem(indey, index).status == 0 || getLineItem(indey, index).status == 4"
                    class="reserve-item" :style="{
                      height: '72px',
                      top: indey * 72 + 'px',
                      left: index * 112 + 'px',
                    }">
                    <!-- <span>休息</span> -->
                  </div>
                  <!-- <div v-if="getLineItem(indey, index).status == 3" class="reserve-item occupyTime" :style="{
                      height: '72px',
                      top: indey * 72 + 'px',
                      left: index * 112 + 'px',
                    }">
                      已占用
                    </div> -->
                </div>
              </template>
              <template v-else>
                <div class="line-item cursor"
                  :class="{ paibanActive: getLineItem(indey, index).status == 0 && getLineItem(indey, index).scheduling }"
                  @mouseleave.stop="handleReserveLeave" @mouseenter.stop="handleReserveClick(indey, index)">
                  <!-- {{  getLineItem(indey, index).status}} -->
                  <!--休息/空闲-->
                  <div v-if="getLineItem(indey, index).status == 4" class="reserve-item restTime" :style="{
                    height: '72px',
                    top: indey * 72 + 'px',
                    left: index * 112 + 'px',
                  }"></div>

                  <!-- 有排班 && 空闲 -->
                  <div class="dialog" v-if="getLineItem(indey, index).status == 0 && currentTimeIndex == indey &&
                    currentStaffIndex == index && isShowDialog && getLineItem(indey, index).scheduling">
                    <div @click.stop="handleAddReserveClick(indey, index)">
                      新建预约
                    </div>
                    <div @click.stop="handleAddTimeOccupyClick(indey, index)">
                      新建占用
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </template>
        <!--订单信息 1待到店2已到店3已开单4已完成5已取消6已退款-->
        <template v-if="orderArr.length">
          <template v-for="(odr, idx) in orderArr" :key="idx">
            <div v-if="odr.content.isOccupancy" class="order-item" :class="{ 'occupy': odr.content.isOccupancy }"
              @click="openOrderPopup(odr.content)" :style="{
                height: odr.location.h * 72 + 'px',
                top: odr.location.y * 72 + 'px',
                left: odr.location.x * 112 + 'px',
              }">
              <div class="order-details">
                已占用
              </div>
            </div>
            <div v-else class="order-item" :class="'status-' + odr.content.orderInfo.orderStatus"
              @click="openOrderPopup(odr.content)" :style="{
                height: odr.location.h * 72 + 'px',
                top: odr.location.y * 72 + 'px',
                left: odr.location.x * 112 + 'px',
              }">
              <div class="order-details">
               <p class="tips">
                  {{ odr.content.orderInfo.customerName }}
                  <span class="ispoint" v-if="odr.content.orderInfo.customerType == 1">新客</span>
                  <span class="ispoint" v-if="odr.content.orderInfo.customerType == 2">回客</span>
                </p>
                <p>{{ odr.content.orderInfo.customerPhone }}</p>
                <p>{{ odr.content.orderInfo.serverName }}</p>
              </div>
            </div>
          </template>
        </template>
      </div>

    </div>


    
  </div>

  <el-drawer v-model="dialogVisible" size="20%">
      <order-card :currentDialog="currentDialog" :roomOptions="roomOptions" @confirmAppAction="confirmAppAction"
        @handleReserveDetailClick="handleReserveDetailClick" @makeABillAction="makeABillAction"
        @cancelOccupancy="cancelOccupancy"></order-card>
    </el-drawer>
    <add-reserve-dialog :currentStaffIndex="currentStaffIndex" :addInfo="addInfo" :dialogVisible="addReserveDialogVisible"
      @changeDialogVisible="changeAddReserveDialog">
    </add-reserve-dialog>
    <add-time-occupy-dialog v-if="addTimeDialogVisible" @refreshOccupyTime="refreshOccupyTime" :addInfo="addInfo"
      :dialogVisible="addTimeDialogVisible" @changeDialogVisible="changeAddTimeDialog"></add-time-occupy-dialog>
    <reserve-edit-draw v-if="pageEditReserveDialogVisible" @refreshPage="refreshUserWeekReservation" :isCheck="true"
      :reserve="currentReserve" @changedDrawer="handleReserveEditdDrawer" :dialogVisible="pageEditReserveDialogVisible">
    </reserve-edit-draw>
</template>

<script>
import { defineComponent, reactive, ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import addReserveDialog from '../base-ui/add-reserve-dialog/add-reserve-dialog'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'
import addTimeOccupyDialog from '../base-ui/add-time-occupy-dialog/add-time-occupy-dialog.vue'
import dayjs from 'dayjs'
import reserveEditDraw from '../../../list/cpns/reserve-list/cpns/base-ui/reserve-edit-draw/reserve-edit-draw.vue'
import bottomTips from '../../../../reserve/check/cpns/base-ui/bottom-tips/bottom-tips.vue'
import { useRoute, useRouter } from 'vue-router'
import { deepClone } from '@/utils/clone'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'
import { getShopTime, confirmApp, makeABill, getNewRooms, getBedListByOrder,getUserWeekReservation } from '@/service/main/reserve'
import {
  debounce
} from 'lodash'
import OrderCard from "../../../../reserve/check/components/card.vue"
// import { reserveTimeList_30, reserveTimeList_60 } from '../config'
// import { messageComment } from '@/service/main/base'
// import { useShopTime } from '../config'

export default defineComponent({
  props: {
    contentWidth: {
      type: Number,
      default: 1200
    }
  },
  components: {
    addReserveDialog,
    addTimeOccupyDialog,
    reserveEditDraw,
    bottomTips,
    OrderCard
  },
  emits: ['scrollFixed', 'scrollRemoveFixed', 'changeAddReserve'],
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const staffList = ref([])
    const reservationBoardList = ref([])
    const reserveTimeList = ref([])
    const timeArr = ref([])
    const orderArr = ref([])
    const dialogVisible = ref(false)
    const currentDialog = ref(null)
    const kanbanDetail = ref(null)

    const bedOptions = ref([])
    const roomOptions = ref([])
    // const reserveTimeList = reactive({
    //   ten:[],
    //   thirty:[],
    //   sixty:[]
    // })

    const roomChage = (e) => {
      const index = roomOptions.value.findIndex(r => r.roomId == e)
      bedOptions.value = roomOptions.value[index].bedIds
    }

    const openOrderPopup = (item) => {
      if (item.isOccupancy) {
        if (item.expire) return
      } else {
        if (![1, 2, 4, 6].includes(item.orderInfo.orderStatus)) return
      }
      currentDialog.value = item
      if(!item.isOccupancy){
        getBedListByOrder(currentDialog.value.orderInfo.orderNo).then(res => {
          // roomOptions.value = res.data.list
          roomOptions.value = res.data.map(t=>({
            ...t,
            enabled:t.bedList.some(b=>b.enabled)
          }))
          dialogVisible.value = true
        })
      }else{
        dialogVisible.value = true
      }
    }
    const handleCloseDialog = () => {
      dialogVisible.value = false
    }
    // const reserveTimeList = reactive({
    //   ten:[],
    //   thirty:[],
    //   sixty:[]
    // })
    const handleOpenKaidanClick = () => {
      router.push({
        path: '/main/order/billing',
        query: {
          userId: currentDialog.value.orderInfo.userId,
          orderNo: currentDialog.value.orderInfo.orderNo,
          phone: currentDialog.value.orderInfo.customerPhone
        }
      })
    }
    const getLineItem = (indey, index) => {
      return timeArr.value[index].timeList[indey]
    }
    const ruleForm = reactive({
      shopId: route.query.shopId,
      time: dayjs(new Date()).format('YYYY-MM-DD'),
      timeDiff: Number(route.query.timeDiff) || '10'
    })

    // const refreshShoplist = async (shopId)=>{
    //   const res = await getReserveShopList(shopId)
    //   staffList.value = res.data.list.filter(ele => ele.status == 1)
    // }

    // const refreshReservationBoard =  async (data) => {
    //   let list = await  reservationBoard(data)
    //   reservationBoardList.value = list.data.reservationreservationList
    // }
    const initPage = async () => {
      // let adminInfo = cache.getCache('adminInfo')
      // if (ruleForm.shopId == '') {
      //   ruleForm.shopId = adminInfo.shopId
      // }

      // let reserveTimeDiff = cache.getCache('reserveTimeDiff')
      // if (reserveTimeDiff != undefined) {
      //   ruleForm.timeDiff = reserveTimeDiff
      // }

      const times = await getShopTime(ruleForm.shopId)
      let arr = times.data
      // reserveTimeList.ten = times.data
      reserveTimeList.value = times.data.map((i, idx) => {
        let arr = i.time.split(':');
        return {
          ...i,
          thirty: arr[1] == '30' || arr[1] == '00',
          sixty: arr[1] == '00'
        }
      })

      refreshUserWeekReservation(params)

    }

    // const refreshPage = ()=>{
    //   refreshShoplist(ruleForm.shopId)
    //   refreshReservationBoard({
    //     date: ruleForm.time,
    //     shopId: ruleForm.shopId
    //   })
    // }

    const currentReserve = ref('')
    const pageEditReserveDialogVisible = ref(false)
    const handleReserveEditdDrawer = (flag) => {
      pageEditReserveDialogVisible.value = flag
      emit('changeAddReserve', flag)
    }

    const handleReserveDetailClick = (item) => {
      currentReserve.value = item.orderInfo
      pageEditReserveDialogVisible.value = true
      // reservedForm.show = false
    }

    const isShowDialog = ref(false)
    const currentTimeIndex = ref(-1)
    const currentStaffIndex = ref(-1)
    const handleReserveClick = (indey, index) => {
      // console.log('点击看板')
      currentTimeIndex.value = indey
      currentStaffIndex.value = index
      isShowDialog.value = true
    }

    const refreshUserWeekReservation = (data) => {
      getUserWeekReservation(data).then(res => {
        let list = res.data.reservationreservationList
        kanbanDetail.value =  res.data
        let arr = []
        // timeArr.value = list.map(item=>{
        //   return {
        //     ...item,
        //     week: getWeeks(item.date)
        //   }
        // })

        let obj = {}
        list.forEach((item, index) => {
          arr.push({
            ...item,
            week: getWeeks(item.date)
          })
          item.timeList.forEach((t, i) => {
            let { orderNo } = t
            if (orderNo) {
              if (obj[orderNo]) {
                obj[orderNo].timeChild.push({
                  pIndex: index,
                  sIndex: i
                })
              } else {
                let o = {
                  content: { ...t, isOccupancy: false },
                  timeChild: [{
                    pIndex: index,
                    sIndex: i
                  }]
                }
                obj[orderNo] = o
              }
            } else {
              let { occupancyNo } = t
              if (t.status == 3) {
                if (obj[occupancyNo]) {
                  obj[occupancyNo].content.timeStr += `,${t.time}`
                  obj[occupancyNo].timeChild.push({
                    pIndex: index,
                    sIndex: i,
                  })
                } else {
                  const { date, userId, } = item
                  let o = {
                    content: { ...t, isOccupancy: true, date, userId, timeStr: t.time },
                    timeChild: [{
                      pIndex: index,
                      sIndex: i
                    }]
                  }
                  obj[occupancyNo] = o
                }
              }
            }
          })
        })
        for (let key in obj) {
          let arr = obj[key].timeChild
          obj[key].location = {
            x: arr[0].pIndex,
            y: arr[0].sIndex,
            h: arr.length
          }
        }
        // console.log('处理之后的预约信息：', obj)
        timeArr.value = arr
        orderArr.value = Object.values(obj)
      })
    }

    const getWeeks = (date) => {
      let weekArrayList = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六',]
      let index = new Date(date).getDay()
      return weekArrayList[index]
    }
    const handleReserveLeave = debounce(() => {
      isShowDialog.value = false
    }, 100)

    const addInfo = reactive({
      teacherId: '',
      startTime: ''
    })

    const handleAddReserveClick = (indey, index) => {
      addInfo.lastTime = reserveTimeList.value[reserveTimeList.value.length - 1].time
      addInfo.teacherName = timeArr.value[index].userName
      addInfo.teacherId = timeArr.value[index].userId
      addInfo.startTime = reserveTimeList.value[indey].time
      addInfo.lvid = timeArr.value[index].lvid
      isShowDialog.value = false
      currentStaffIndex.value = index
      addReserveDialogVisible.value = true
    }


    const addReserveDialogVisible = ref(false)
    const changeAddReserveDialog = (flag) => {
      addReserveDialogVisible.value = flag
      refreshUserWeekReservation(params)
    }

    const addTimeDialogVisible = ref(false)
    const handleAddTimeOccupyClick = (indey, index) => {
      const item = timeArr.value[index]
      const ele = reserveTimeList.value[indey]
      addInfo.teacherName = item.userName
      addInfo.teacherId = item.userId
      addInfo.startTime = ele.time
      addInfo.lvid = item.lvid
      addTimeDialogVisible.value = true
    }
    const refreshOccupyTime = () => {
      refreshUserWeekReservation(params)
    }
    const changeAddTimeDialog = (flag) => {
      addTimeDialogVisible.value = flag
    }

    // created
    initPage()


    const checkIsShowLine = (indey) => {
      let res = false
      if (ruleForm.timeDiff == 10) {
        res = true
      }
      if (ruleForm.timeDiff == 30) {
        res = reserveTimeList.value[indey].thirty
      }
      if (ruleForm.timeDiff == 60) {
        res = reserveTimeList.value[indey].sixty
      }
      return res
    }



    const params = reactive({
      userId: route.query.userId,
      startDate: '',
      endDate: ''
    })
    // const handleTeacherClick = (item) => {
    //   cache.setCache('optReserveDate', ruleForm.time)
    //   router.push({
    //     path: '/main/reserve/teacherDetailCheck',
    //     query: {
    //       userId: item.userId,
    //       userRealname: item.userRealname,
    //       shopName: item.shopName
    //     }
    //   })
    // }


    emitter.on('teacherDetailCheckOptDateChange', value => {
      // console.log('改变日期1111111', value)
      if (value == null) {
        Object.assign(params, {
          startDate: '',
          endDate: ''
        })
      } else {
        const date1 = dayjs(value[0])
        const date2 = dayjs(value[1])
        let days = date2.diff(date1, 'day')
        if (days > 31) {
          ElMessage({
            message: '不得超过31天!',
            type: 'warning',
          })
          return
        }
        Object.assign(params, {
          startDate: dayjs(value[0]).format('YYYY-MM-DD'),
          endDate: dayjs(value[1]).format('YYYY-MM-DD')
        })
      }
      refreshUserWeekReservation(params)
      // ruleForm.start = dayjs(value[0]).format('YYYY-MM-DD')
    })

    onUnmounted(() => {
      emitter.off('teacherDetailCheckOptDateChange ')

    })

    const confirmAppAction = (e) => {
      // const {appNo,arvKind,arvPerson} = currentDialog.value.orderInfo
      // Object.assign(e, {
      //   arvTime:dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      //   appNo,
      //   arvKind,
      //   arvPerson
      // })
      confirmApp(e).then(res => {
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })
          refreshUserWeekReservation(params)
          dialogVisible.value = false
        } else {
          ElMessage.error(res.msg)
          dialogVisible.value = false
        }
      })
    }

    const cancelOccupancy = () => {
      dialogVisible.value = false
      refreshUserWeekReservation(params)
    }
    const makeABillAction = () => {
      const { appNo, orderNo } = currentDialog.value.orderInfo
      makeABill({
        appNo,
        orderNo
      }).then(res => {
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })

          dialogVisible.value = false
          if (res.data.orderPayStatus == 3) {
            handleOpenKaidanClick()
          } else {
            refreshUserWeekReservation(params)
          }
        } else {
          ElMessage.error(res.msg)
          dialogVisible.value = false
        }
      })
    }
    return {
      ruleForm,
      reserveTimeList,
      staffList,
      reservationBoardList,
      handleReserveClick,
      isShowDialog,
      currentTimeIndex,
      currentStaffIndex,
      handleAddReserveClick,
      handleAddTimeOccupyClick,
      handleReserveLeave,
      // handleTeacherClick,
      getLineItem,
      checkIsShowLine,
      // refreshShoplist,
      timeArr,
      orderArr,
      dialogVisible,
      currentDialog,
      openOrderPopup,
      handleCloseDialog,
      roomOptions,
      bedOptions,
      roomChage,
      addInfo,
      addReserveDialogVisible,
      changeAddReserveDialog,
      confirmAppAction,
      makeABillAction,
      changeAddTimeDialog,
      refreshOccupyTime,
      handleReserveEditdDrawer,
      pageEditReserveDialogVisible,
      currentReserve,
      handleReserveDetailClick,
      cancelOccupancy,
      kanbanDetail
    }

  }
})
</script>

<style scoped lang="less">
.tips{
  position:relative;
  // padding-top: 18px;
}
.ispoint {
  display: inline-block;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #fd678b;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  background-color: #fff;
  font-weight: 400;
  color: #fd678b;
  line-height: 16px;
  padding: 0 5px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
}

.bottom-date-bar {
  position: fixed;
  z-index: 1000;
  bottom: 10px;
  overflow-x: auto;

  .date-item {
    height: 1px !important;
  }
}

.diag-title {
  line-height: 30px;
}

.diag-select {
  margin-top: 20px;
  display: flex;

  .el-select {
    margin-right: 5px;
  }
}

// .title {
//   overflow: hidden;
// }

.title,
.bottom-date-bar {
  // width: 100%;

  .title-wrap {
    // width: 100%;
    display: flex;
    margin-left: 80px;
    // position: relative;
  }

  .date-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 55px;
    min-width: 112px;

    .date-week {
      margin-top: 6px;
      font-size: 13px;

    }

    div {
      width: 112px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(80, 80, 80, 1);
      font-size: 14px;
      // cursor: pointer;
    }
  }
}

.pagination {
  text-align: right;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.line-box.paibanActive:nth-of-type(1) {
  border-left: 1px solid rgb(220, 220, 220);
}

.paibanActive {
  height: 72px !important;
  border-right: 1px solid rgba(201, 197, 197, 1);
  background-color: white !important;
}

.shop-room {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &:deep(.el-select) {
    max-width: 146px;
  }
}

.staffNameFiexed {
  position: fixed;
  width: 88.5% !important;
  top: 180px;
  background-color: white;
  z-index: 100;
  border-bottom: 2px solid rgb(241, 243, 244);
}

.title {
  width: 100%;
  display: flex;
  // padding-left: 80px;

  // div {
  //   width: 112px;
  //   min-width: 112px;
  //   height: 55px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   color: rgba(80, 80, 80, 1);
  //   font-size: 14px;
  //   cursor: pointer;
  //   word-spacing: normal;
  //   font-weight: 700;
  // }
}

.physiotherapy-list {
  position: relative;
  padding-bottom: 250px;

  .reserve-dialog {
    position: absolute;
    z-index: 99;
    width: 343px;
    color: rgba(80, 80, 80, 1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    padding: 20px;
    background-color: #fff;
    overflow: hidden;

    .opt-select {
      margin: 20px 0;

      &:deep(.el-select) {
        width: 100%;
      }
    }

    .caozuo-btn {
      display: flex;
      justify-content: space-between;

      &:deep(.button1) {
        flex: 1.5;
      }

      &:deep(.button2) {
        flex: 1;
      }
    }

    .genjin {
      position: relative;
      padding-bottom: 18px;

      &:deep(.el-input__inner) {
        height: 50px;
        line-height: 50px;
      }

      &:deep(.el-input__suffix) {
        top: 6px;
      }

      .genjin-text {
        position: absolute;
        width: 100%;
      }

      .submit {
        position: absolute;
        z-index: 100;
        right: 4px;
      }
    }

    .current-tips {
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 20px 0;

      div {
        line-height: 30px;
        text-align: center;
      }
    }

    .top {
      display: flex;
      justify-content: space-between;

      .btns {
        display: flex;
        flex-direction: column;

        &:deep(.el-tag) {
          margin-top: 10px;
        }

        .other {
          cursor: pointer;
          font-size: 12px;
          color: rgb(102, 177, 255);
          text-align: center;
        }

        .cancle {
          margin-top: 7px;
          width: 60px;
          height: 25px;
          background-color: rgba(255, 255, 255, 1);
          color: rgb(96, 123, 181);
          border-radius: 3px;
          font-size: 12px;
          text-align: center;
          line-height: 25px;
          cursor: pointer;
          box-sizing: border-box;
          border: 1px solid rgb(220, 223, 230);

          &:hover {
            border: 1px solid rgba(42, 130, 228, 1);
            color: rgba(42, 130, 228, 1);
          }
        }
      }

      .user-info {
        display: flex;

        .uname {
          .user-name {
            cursor: pointer;
            color: rgb(64, 158, 255);
          }

          .name {
            color: rgba(80, 80, 80, 1);
            font-size: 16px;
            display: flex;

            .isPoint {
              flex-shrink: 0;
              margin-left: 6px;
              width: 50px;
              height: 16px;
              border-radius: 2px;
              border: 1px solid #fd678b;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #fd678b;
              line-height: 16px;
              text-align: center;
            }
          }

          .phone {
            color: rgba(80, 80, 80, 1);
            font-size: 14px;
            margin-top: 4px;
          }
        }

        .avatar {
          margin-right: 10px;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .occupyTime {
    color: #fff;
    background-color: rgb(240, 183, 89);
  }

  .reserve-item {
    border: 1px solid white;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 12px;
    padding-top: 20px;
    cursor: pointer;

    &>div:nth-child(2) {
      margin-top: 2px;
    }
  }


  .restTime {
    // background: repeating-linear-gradient(50deg, #000, #000 5px, #fff 5px, #fff 12px);

    background: repeating-linear-gradient(45deg,
        #d3d3d3,
        #d3d3d3 2px,
        #e5e5e5 0,
        #e5e5e5 16px);
    background-repeat: repeat-y;
    border-right: 1px solid rgb(201, 197, 197) !important;

    span {
      z-index: 999;
    }
  }

  .time {
    display: flex;
    width: 100%;
    position: relative;

    // overflow: hidden;
    .time-scale {
      position: absolute;
      z-index: 89;
      width: 80px;
      background-color: #fff;

      // display: flex;
      // flex-wrap: wrap;
      div {

        height: 72px;
        color: rgba(80, 80, 80, 1);
        font-size: 14px;
        text-align: center;
      }

      &>div:nth-child(4n + 1) {
        font-weight: 556;
      }
    }

    .line-list {
      position: absolute;
      z-index: 9;
      width: calc(100% - 80px);
      left: 80px;

      .order-item {
        position: absolute;
        // background: red;
        color: #fff;
        width: 112px;
        box-sizing: border-box;
        border: 1px solid #c9c5c5 !important;
        cursor: pointer;

        &.status-1 {
          background-color: rgb(121, 72, 234);
        }

        &.status-2 {
          background-color: rgb(36, 153, 84);
        }

        &.status-3 {
          background-color: rgb(42, 130, 228);
        }

        &.status-4 {
          background-color: rgb(19, 194, 194);
        }

        &.status-5 {
          background-color: rgb(240, 183, 89);
        }

        &.status-6 {
          background-color: rgb(227, 91, 91);
        }

        &.occupy {
          background-color: rgb(240, 183, 89) !important
        }

        .order-details {
          // padding: 6px;

          p {
            padding: 0 5px;
            word-break: break-all;
            color: #fff;
            font-size: 12px;
            line-height: 20px;
          }
        }

      }

      .line-box {
        display: flex;

        &.last div {
          border-bottom: 1px solid rgb(201, 197, 197);
        }

        &>div:nth-child(1) {
          border-left: 1px solid rgb(201, 197, 197);
        }

        .cursor {
          cursor: pointer;
        }

        .line-item {
          position: relative;
          width: 112px;
          min-width: 112px;
          height: 72px;
          overflow: hidden;
          border-top: 1px solid rgb(201, 197, 197);
          background-color: rgb(245, 245, 245);
          border-right: 1px solid rgb(201, 197, 197);
          padding-top: 1px;

          .dialog {
            display: flex;
            flex-direction: column;
            background-color: rgb(77, 77, 77);
            color: white;
            font-size: 13px;
            text-align: center;

            .empty-class {
              background-color: rgb(211, 211, 211);
              height: 72px;
              color: rgb(63, 60, 60);
              line-height: 72px;
            }

            div {
              line-height: 36px;
              cursor: pointer;
            }
          }
        }
      }

      &>div {
        height: 72px;
        z-index: 9;
        // border-top: 1px solid rgba(220, 220, 220, 1);
      }

      // &>.line-box:last-child div {
      //   border-bottom: 1px solid #c9c5c5;
      // }
    }
  }
}</style>
